import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Roadmap from './components/Roadmap';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Team from './components/Team';
import Redemption from './components/Redemption';
import Whitelist from './components/Whitelist';
import Buy from './components/Buy';




function App() {
  return (
    <div className="App">
      
      <Home />
      <Redemption />
      <Roadmap />
      <Team />
      <Faq />
      <Whitelist />
      <Buy />
      <Footer />
    </div>
  );
}

export default App;
