import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CONTRACT_ADDRESS, CONTRACT_ABI } from '../config';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import whitelistAddresses from './../whitelistAddresses.json';
import CountdownTimer from './CountdownTimer';

const Home = () => {

	var provider;
	var web3;
	var contract;
	
	const [accountCoonected, setAccountCoonected] = useState(false);
	const [myWeb3, setMyWeb3] = useState(false);
	const [myContract, setMyContract] = useState(false);
	const [checkextension, setCheckextension] = useState(false)

	const providerOptions = {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				infuraId: "592d9920e4f34694ac874b698cf19eb2",
				chainId: 1,
			}
		}
	};
	let web3Modal = new Web3Modal({
		cacheProvider: false,
		providerOptions,
	});

	async function handleAccounts() {

		console.log("Opening a dialog", web3Modal);
		provider = await web3Modal.connect();

		provider.on("accountsChanged", (accounts) => {
			//alert('1');
			init2();

		})

		provider.on("networkChanged", (networkId) => {
			//alert('2');
			init2();
		});

		await init2();

	}
      
	useEffect(()=>{
       const checkMetaextension = window.ethereum && window.ethereum.isMetaMask
        if(checkMetaextension===true){
            setCheckextension(true)
        }
        else{
            setCheckextension(false)
        }
    },[])

	async function init2() {
		web3 = new Web3(provider);
		setMyWeb3(web3);
		contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
		setMyContract(contract);
		web3.eth.getAccounts(function (err, accounts) {
			if (web3.currentProvider.chainId != 1) {
				alert('connect your wallet with Ethereum main net');
				let userAddress = accounts[0];
				//alert(accounts[0]);
			}
			else {
				setAccountCoonected(true);
			}
			if (accounts.length === 0) {
				//alert(accounts[0]);
			}
		});
	}

	useEffect(() => {
          
		//handleAccounts();
	}, []);

	const openMetaMask = async (mintAmount) => {
		//handleAccounts();
		// web3 = new Web3(provider);
		let accounts = await myWeb3.eth.getAccounts();
		let selectedAccountCustom = accounts[0];
		let Cost = await myContract.methods.cost().call();
		let isWhiteListEnabled = await myContract.methods.onlyWhitelisted().call();
		//let mintAmount = 1;
		if (isWhiteListEnabled) {
			let addressIndex = whitelistAddresses.indexOf(selectedAccountCustom);

			if (addressIndex == -1) {
				return alert('you are not whitelisted');
			}

			console.log("length = " + whitelistAddresses.length);
			console.log("index = " + addressIndex);
			console.log("whitelist status = " + isWhiteListEnabled);
			const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
			const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
			const rootHash = merkleTree.getRoot();
			console.log('root hash = ', rootHash.toString("hex"));
			console.log('whitelist merkle tree\n', merkleTree.toString());
			const claimingAddress = leafNodes[addressIndex];
			const hexProof = merkleTree.getHexProof(claimingAddress);
			console.log('hexProof \n', hexProof);

			myContract.methods.whitelistMint(mintAmount, hexProof).estimateGas({ from: selectedAccountCustom, value: Cost * mintAmount }).then(function (gasAmount) {
				myContract.methods.whitelistMint(mintAmount, hexProof)
					.send({
						from: selectedAccountCustom,
						value: Cost * mintAmount
						//value: web3.utils.toWei((cost * amount).toString() , "ether")
					});
			});

		}
		else {
			myContract.methods.mint(mintAmount).estimateGas({ from: selectedAccountCustom, value: Cost * mintAmount }).then(function (gasAmount) {
				myContract.methods.mint(mintAmount)
					.send({
						from: selectedAccountCustom,
						value: Cost * mintAmount
						// value: 0
						//value: web3.utils.toWei((cost * amount).toString() , "ether")
					});
			});
		}
	}

	const handleMint = (event) => {
		event.preventDefault();
		const { amount } = event.target.elements;
		if (isNaN(amount.value)) {
			return alert("Please enter number only");
		}
		if (amount.value == '') {
			return alert("Please choose a number of NFTs to mint");
		}

		openMetaMask(amount.value);
	}

	return (
		<>
			<header className="header_main page-header affix">
				<div className="container">
					<nav className="navbar navbar-expand-sm  navbar-dark">
						{/* <!-- Brand --> */}
						<a className="navbar-brand logo" ><span>Zen Lion Massage Club</span></a>
						{/* <!-- Toggler/collapsibe Button --> */}
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
							<span className="navbar-toggler-icon"></span>
						</button>
						{/* <!-- Navbar links --> */}
						<div className="collapse navbar-collapse" id="collapsibleNavbar">
							<ul className="navbar-nav">
								<li className="current-menu-item">
									<a className="nav-link active" href="#home">Home</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#roadmap">Roadmap</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#whitelist">VIP List</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#home">Buy a Lion</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#redemption">Redemption</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#faq">FAQ</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#hiring">Hiring</a>
								</li>
								<div class="connect-wallet">


									{
										accountCoonected
										&&
										<button className='nav-link mint_btn' onClick={handleAccounts}>Connected</button>
									}
									{!accountCoonected && <button className='nav-link mint_btn' onClick={handleAccounts}>Connect Wallet</button>}
								</div>
							</ul>
						</div>
					</nav>
				</div>
			</header>
			<div id="home" class="hero-wrap">
				<div class="container-1 relative z-5 hero w-container">
					<div class="vert-left">
						<div>
							<h1>Zen Lion Massage Club</h1>
							<p class="p1">Get<b> $20 OFF</b> in-home massage<b> FOR LIFE </b>with our exclusive NFT!<br /></p>
							<a href="https://discord.gg/W3Q2PVN3qY" target="_blank" class="button _2nd w-button">Join Discord </a>
							{/* <a href="#" target="_blank" class="button _2nd w-button mint_btn">MINT </a> */}

							{
								accountCoonected
								&&
								<form onSubmit={handleMint}>
									<input type="number" name="amount" id="amount" placeholder="0" min={0}/>
									<button type="submit" className='button _2nd w-button mint_btn'>Mint NFT</button>
								</form>
							}
							{!accountCoonected && <button className='button _2nd w-button mint_btn' onClick={handleAccounts}>Connect Wallet</button>}
							<div className="top_countdown">
								<div class="container">
									<CountdownTimer />
									{checkextension===false?<div className="p1"><span>*Before you connect install <a style={{cursor:'pointer',color:"#ff9900d6"}} target="_blank" href='https://metamask.io/download/'>MetaMask</a> for your browser, iOS or Android</span></div>:<></>}
									{/* <div class="countdown">{timerDays || '0'} : {timerHours || '00'} : {timerMinutes || '00'} : {timerSeconds || '00'}</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Welcome section --> */}
			<div id="welcome">
				<div class="max-w-5xl mx-auto">
					<div class="container">
						<div class="text-white">
							<h2 class="gradient-title">Your zen in the den</h2>
							<p>Like all humans, every Zen Lion is unique and enjoys self-care. Whether you’re the king or
								queen of your den, you deserve a break! We invite you to become one with our pride and while
								supporting our national brands growth across the land your body will appreciate you with our
								<b> $20 OFF</b> any in-home massage service utility!  </p>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- ABout Section --> */}
			<div id="about">
				<div class="max-w-5xl mx-auto">
					<div class="grid ">
						<div class="relative mx-auto">
							<div class="absolute bottom-3 left-3">
								<div class="flex items-center gap-1"></div>
							</div>
							<img class="w-96 h-auto" src="images/sleepLion.png" alt="" />
						</div>
						<div class="text-white">
							<h2 class="gradient-title">About Us</h2>
							<p>The Zen Lion NFT is a limited 6,000 piece 3D art collection that offers value in a multitude of ways while appealing to all your senses.<br /><br /> Aside from the hit of nostalgia when viewing the epic artwork, get treated like royalty whilst you rest under ambient light, listen to soothing music and embrace the subtle aroma of scented oils.
								<br /><br />Enjoy a spa-experience in the comfort of your home!
								<br /><br />The lions are enjoying complete zen while hanging out, stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS.<br /><br /><a href='#'> Mint a Zen Lion</a><br /><br />Buying a lion costs <b>0.03 ETH</b> on the Public Mint date, <b>March 13, 2023</b>  </p>
						</div>
					</div>
				</div>
			</div>

			<div id="image-gallery">
				<div class="container">
					<div class="row" >
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="slide">
								<img src="images/ZenLion (4).png" />
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="slide">
								<img src="images/ZenLion(2).png" />
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="slide">
								<img src="images/ZenLion(3).png" />
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="slide">
								<img src="images/ZenLion.png" />
							</div>
						</div>
					</div>
				</div>
				{/* <div class="container">
					<p>TBA pre-sale: 150 whitelist addresses can mint up to two Zen Lions<br /><br />TBA public sale: 5600 Zen Lion NFTs available to mint from any wallet<br /><br />Note: 100 Lions are being withheld from the sale. These will be used for giveaways, fundraisers for charity and team memberships</p>
				</div> */}
				<div class="container">
					<p><b>March 13, 2023 at 10:00 AM EST</b><br /><br />Any wallet can mint at 0.03 ETH unlimited Zen Lions (until sold-out)<br /><br /><b>Note : </b>100 Lions withheld for giveaways, charity and team memberships</p>
				</div>
			</div>


			{/* <!-- section4 Start--> */}
			<div id="section4">
				<div class="sec4_overlay">
					<div class="max-w-5xl mx-auto">
						<div class="container">
							<div class="text-white">
								<h2 class="gradient-title">WELCOME TO THE CLUB, RELAX IN COMFORT</h2>
								<p>Each token offers an exclusive <b>$20 OFF</b> redemption code to book in-home massage!<br /><br />Get a professional tailored massage, our experts come to you. <br /><br />*For <b>personal use</b>, apply 1 code per booking, <b>no expiration</b><br /><br /><b>*Transferrable </b>as the NFT exchanges ownership<br /><br />Token redeemable on<b> March 20th, 2023</b> (one-week after public mint). <br /><br />Get ready to book your zen in the den!<br /><br />Serving more than 200 major cities and their surrounding areas across Canada and the United States!<br />
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Section6 Start --> */}
			<div id="section6">
				<div class="max-w-5xl mx-auto container">
					<div class="grid ">
						<div class="text-white">
							<h2 class="gradient-title">YOUR SATISFACTION IS GUARANTEED! </h2>
							<p>If you live in a city we serve and plan on enjoying our home massage services, all bookings will always be backed by our<b> client satisfaction + booking confirmation</b>  guarantees.<br /><br />This means if you are not impressed with our on-site service it’s free and if you have any trouble	scheduling a desired booking preference we will buy-back your NFT token for the original 0.03 ETH mint price paid.<br /><br />We value your time and investment, so want to offer peace of mind while relaxing in your lions den!</p>
						</div>
						<div class="relative mx-auto">
							<div class="absolute bottom-3 left-3">
								<div class="flex items-center gap-1"></div>
							</div>
							<img class="w-96 h-auto" src="images/NFTMint.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;