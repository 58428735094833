const Faq = () => {

	return (
		<>
			<div className="home_faq" id="faq">
				<div className="container">
					<div className="home_faq_max">
						<div className="home_road_map_heading">
							<h3 className="text-center gradient-title">FREQUENTLY<br /> ASKED QUESTIONS</h3>
						</div>
						<div className="">
							<div className="home_faq_flex">
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab18">
										<h6 className="accordion">What is a Zen Lion NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab18">
											<p>Zen Lion NFT is the name of Massage Club’s exclusive NFT, created to bring the founders vision of a global community to fruition and to offer our professional home massage service to a broader market. By owning a Zen Lion NFT you get two things; you own an epic piece of 3D lion art and get to enjoy a lifetime discount on our in-home massage throughout North America in the 200+ cities we serve per our $20 OFF code which is the real world utility… unlimited redemptions (apply once per booking) and it’s available for life, no expiry! Join our lion pride by becoming part of the ZLMC community and feel great! </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab19">
										<h6 className="accordion">Who can benefit from the NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab19">
											<p>Anyone over the age of 19 can buy a Zen Lion NFT and enjoy our $20 OFF code for inhome massage if you reside in any of the 200+ cities we serve within Canada and the United States. Not sure if we service your area? Visit our redemption site homepage massageclub.ca and search your city or the closest major city we list, as we often travel up to 100 km/mi outside of each major city we serve to include the smaller areas that may not be listed when booking.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab20">
										<h6 className="accordion">I don’t live in a city that’s served for it’s utility, should I buy this NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab20">
											<p>Your investment will help us to expand our operations both nationally and to a global front, thus employing thousands more massage experts and we will likely be in a city you live in soon! Our plan is to expand to all the smaller cities within Canada and the United States first, then Europe next. If you live abroad, but know someone in a city we serve you can own the NFT yourself and redeem it in their name so they can enjoy the $20 OFF discount on our services, then sell it when you’d like and have potential for profit.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab21">
										<h6 className="accordion">How can we offer such a value based utility for low initial cost?</h6>
										<div className="panel accordian-para acc-show" id="tab21">
											<p>Brand recognition will always come with a time and monetary cost. Any company who wishes to be known and grow especially from a national to global level requires a healthy advertising and marketing budget. The initial sales will cover a portion of the discounted services being booked, while the royalties from those who choose to sell their Zen Lion NFT’s will cover the web/app development costs and hiring efforts. The aim is to reinvest anything above this into our development/expansion efforts while also offering additional perks to our clients, valued team members and development team in appreciation for the support throughout this project to further enhance our company and scheduling platform. We also believe in the ETH token, and while it’s at a low now, we will benefit should it rise over time and so will you!</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="purchasing-faq">
							<h4>Purchasing FAQ</h4>
							<div className="home_faq_flex">
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab1">
										<h6 className="accordion">Where do I buy the Zen Lion NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab1">
											<p>You can buy (Mint) your Zen Lion NFTs here on <b>https://zenlion.ca</b>  but you will need to connect to a MetaMask wallet to purchase them using Ethereum (ETH). Make sure to have some ETH in your wallet to cover the<b> 0.03 mint price + gas</b>  so you don’t miss out.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab2">
										<h6 className="accordion">When can I purchase?</h6>
										<div className="panel accordian-para acc-show" id="tab2">
											<p>Our Public Mint will be on <b>March 13, 2023 at 10:00 AM EST</b> for anyone to buy! Day of mint you’ll be able to purchase on our<b> https://zenlion.ca  </b> homepage. No limit to how many you can mint. </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab3">
										<h6 className="accordion">WILL ALL 6000 ZEN LION NFTS BE AVAILABLE ON MAR 13, 2023?</h6>
										<div className="panel accordian-para acc-show" id="tab3">
											<p>Yes, less the 100 Lions withheld for giveaways, charity and team memberships.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab4">
										<h6 className="accordion">WILL THERE BE A WHITELIST OR PRESALE? </h6>
										<div className="panel accordian-para acc-show" id="tab4">
											<p>There will not be a whitelist (WL), only a Public Mint. Instead, we will be organizing a VIP Guest-list where we will gather member emails for a friendly reminder of the NFT mint date, you will also receive a special role in our discord and receive chance for additional perks TBA. </p>
										</div>
									</div>
								</div>
								{/* <div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab5">
										<h6 className="accordion">How does our whitelist work?</h6>
									</div>
									<div className="panel accordian-para acc-show" id="tab5">
										<p>To be whitelisted means that you have gone through the process/steps, as defined by our NFT project team <a href="#whitelist">HERE</a>, of <b>getting your ETH address pre-approved to mint an NFT at a predefined date and time</b>. Our whitelist presale date is <b>TBA, 2022</b> and the window of time to buy will be from <b>1:00 PM EST</b> to <b>10:00 PM EST</b>.</p>
									</div>
								</div>  */}
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab6">
										<h6 className="accordion">When is the public sale?</h6>
										<div className="panel accordian-para acc-show" id="tab6">
											<p>Our Public Mint will be on<b> March 13, 2023 at 10:00 AM EST</b> for anyone to buy! Day of mint you’ll be able to purchase on our<b> https://zenlion.ca</b>  homepage. No limit to how many you can mint. You'll see a “Connect Wallet” button with text under it describing how to mint on our Homepage Banner. Once connected to your MetaMask, you’ll be able to set how many NFTs you wish to buy and have chance to review before minting.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab7">
										<h6 className="accordion">Can I purchase the ZenLion NFT with my credit card or PayPal?</h6>
										<div className="panel accordian-para acc-show" id="tab7">
											<p>Unfortunately not. To purchase a Zen Lion NFT you will need a <b>MetaMask </b>digital wallet with<b> Etherium “ETH” </b>in it which is the currency needed to mint. </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab8">
										<h6 className="accordion">Is there a specific digital wallet needed?</h6>
										<div className="panel accordian-para acc-show" id="tab8">
											<p>You will need a non-custodial wallet to be able to purchase (Mint) a Zen Lion NFT and our mint site <b>https://zenlion.ca </b> only accepts wallet payments from<b> MetaMask</b>. You will need to have ETH available in your wallet so you’re prepared to make your order and not miss out on <b>March 13, 2023</b> which is our Public Mint date! </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab9">
										<h6 className="accordion">How do I download and install Metamask?</h6>
									</div>
									<div className="panel accordian-para acc-show" id="tab9">
										<p>For a step-by-step assistance you can visit the MetaMask getting started page <a target="_blank" href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask">HERE</a>.</p>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab10">
										<h6 className="accordion">Can I buy a portion of a ZenLion NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab10">
											<p>You cannot buy a portion of a Zen Lion NFT token.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab11">
										<h6 className="accordion">Can you rent out or gift the NFT token?</h6>
										<div className="panel accordian-para acc-show" id="tab11">
											<p>In order to redeem a benefit we must be able to verify on the blockchain that you own the Zen Lion NFT token at the time the benefit is being deployed or activated. You can not rent out the token<b> $20 OFF code</b> or its benefits, however you can gift it to a single recipients name at time of redemption and while you own the token, they can benefit from this discount utility on any of our in-home massage services until you sell (your code would be deactivated and new owners code activated). No expiry on the utility, it has a life-time value, but you can only apply the discount once per individual booking.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="Token-Redemption-FAQ">
							<h4>Token Redemption FAQ</h4>
							<div className="home_faq_flex">
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab12">
										<h6 className="accordion">How do I redeem the ZenLion NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab12">
											<p>We made the redemption process easy! Once you purchase the Zen Lion NFT, email our company info@zenlion.ca with your <b>token #</b> plus screen shot of the<b> top portion of your MetaMask wallet</b> to verify your ownership.<br /> We will issue you a personalized Massage Club <b>redemption code</b> for <b>$20 OFF</b> any of our in-home massage services which you may book via our website MassageClub.ca</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab13">
										<h6 className="accordion">What is the service utility all token holders receive?</h6>
										<div className="panel accordian-para acc-show" id="tab13">
											<p>Our exclusive Zen Lion NFT offers a valuable<b> $20 OFF code </b>which you may redeem an unlimited amount of times for life! <br />Whether you’re in need of massage therapy or enjoy personal pampering your Massage Club<b> redemption code</b>  for <b>$20 OFF </b>any of our in-home massage services allows you to book an appointment via our website MassageClub.ca in any of our 200+ markets plus the surrounding cities we serve within Canada and the United States! <br /><br />*For <b>personal use</b> (or gift at time of redemption) <br /><br /><b>*Max</b>  apply one code per booking <br /><br /><b>*Valid</b> for bookings only, no expiry <br /><br /><b>*Not valid </b>for Gift Card or Product orders <br /><br /><b>*Transferrable</b> as the NFT exchanges ownership </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab14">
										<h6 className="accordion">WHEN CAN I START USING THE HOME MASSAGE DISCOUNT CODE? </h6>
										<div className="panel accordian-para acc-show" id="tab14">
											<p>Because we are already an established business and in operation, you can benefit from our <b>$20 OFF</b> any service utility and book a home appointment online right away!<br /><br />
												Redemption starts <b>one-week after Public Mint</b>, so on<b> March 20, 2023</b> which will allow us a few days to verify token ownerships, issue the redemption codes and answer any customer inquires during the mint phase. Open a ticket on our discord or email us for any questions.<br /><br />*For <b>personal use</b> (or gift at time of redemption) <br /><br /><b>*Max</b>  apply one code per booking <br /><br /><b>*Valid</b> for bookings only, no expiry <br /><br /><b>*Not valid </b>for Gift Card or Product orders <br /><br /><b>*Transferrable</b> as the NFT exchanges ownership</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab15">
										<h6 className="accordion">How do I book an appointment?</h6>
										<div className="panel accordian-para acc-show" id="tab15">
											<p>After you receive your personalized<b> discount code</b>, schedule conveniently online at MassageClub.ca <br /><br />Fill out the booking form, apply the code at checkout and you will receive your discount on each 60-minute, 90-minute or 120-minute home massage you book. <br /><br />Should you choose to book a single session or couples/group massage, you’ll just pay the difference at checkout for any service you submit.<br /><br />Appointments subject to availability if booking on-demand or next day out. When able to do so we ask clients to pre-book in advance.
											</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab16">
										<h6 className="accordion">What is an in-home massage, who attends and how does it work?</h6>
										<div className="panel accordian-para acc-show" id="tab16">
											<p>Enjoy a spa-quality massage while relaxing in the comfort of your home, office or hotel; we bring the portable massage table, linen and oils or choose a chair massage!<br /><br /><b>Massage Club</b> is a national brand with a team of mobile massage experts; RMT, LMT, CMT and Bodyworkers who can be conveniently scheduled for home visits.<br /><br />You’re invited to book on-demand or schedule in advance - choose from 12 styles of massage and you may select a gender preference for maximum comfort.<br /><br />Insurance receipts issued if selected, valid for insurance reimbursement but because our NFT token does not have a predetermined paid value per session we will be unable to issue insurance receipts for use of this token.<br /><br />Clients can book single sessions, couples or group massage, and at 60-Min / 90-Min / or 120-Min intervals.<br /><br />*All massages are strictly professional and by registered, licensed or certified professionals<br /><br />Our team is to follow strict new health and sanitation guidelines set by the government for your peace of mind.</p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab17">
										<h6 className="accordion">What happens to the service utility if I sell the NFT?</h6>
										<div className="panel accordian-para acc-show" id="tab17">
											<p>While owning the Zen Lion NFT you may use it’s $20 OFF discount code utility as you please, but once sold and the new owner verifies ownership, your personal redemption code will be deactivated and the current owner will have their code issued, unique to them and activated. </p>
										</div>
									</div>
								</div>
								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab29">
										<h6 className="accordion">Are there any other costs?</h6>
										<div className="panel accordian-para acc-show" id="tab29">
											<p>Zen Lion NFT holders receive a $20 OFF code which you may redeem online when booking an in-home massage appointment with Massage Club. Simply enter the code at checkout to receive the discount, and any remaining amount owing can be paid by
												PayPal or credit card for the services you select. <br /><br />*For <b>personal use</b> (or gift at time of redemption) <br /><br /><b>*Max</b>  apply one code per booking <br /><br /><b>*Valid</b> for bookings only, no expiry <br /><br /><b>*Not valid </b>for Gift Card or Product orders <br /><br /><b>*Transferrable</b> as the NFT exchanges ownership<br /><br />The end cost for our services at checkout will cover the massage, travel, set-up, and tax. There are no additional fees.<br /><br />Tipping is at your discretion and would be a cash payment to the therapist if you feel obliged and while customary for this type of service, it’s by no means mandatory.<br /><br />You’re welcome to book longer sessions, couples, or group massage and in doing so you would just pay the difference after applying your discount code.
												<br /><br />The team relies on clients to provide convenient and free on-site parking. For valet or expensive paid parking we ask clients to compensate the provider day of. This is noted on our booking form and is a standard policy as this fee (only when required) is outside of our service.  </p>
										</div>
									</div>
								</div>

								<div className="home_faq_inner">
									<div className="faq_1  ac-title" data-in="#tab39">
										<h6 className="accordion">Can I use my massage benefits or insurance?</h6>
										<div className="panel accordian-para acc-show" id="tab39">
											<p>If you have<b> massage benefits</b> or <b>insurance </b>for registered/licensed massage therapy, our online booking form will ask you whether you need an insurance receipt or not. <br /><br />When selecting the ‘Insurance receipt - Yes’ option, the massage therapist will issue you a signed receipt day of session for the paid amount at time of booking. <br /><br />*The Zen Lion<b> $20 OFF code</b> allows for unlimited uses (apply once per booking), so the $20 discount you apply will not be written on the receipt  <br /><br /><b>Example:</b> A 60-minute booking for 1-person will cost $120 less your $20 code, so your end payment due will be for $100 and your insurance receipt will be written for $100<br /><br />You will enjoy quality care and excellent service on each visit!<br /><br />All service is backed by two guarantees; your satisfaction + appointment confirmation!  </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Faq;



