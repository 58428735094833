const Roadmap = () => {
    return ( 
          <>
            <div className="roadmap real_roadmap"  id="roadmap">
               <div className="container">
                  <h3>ROADMAP</h3>
                  <p className="heading_para">We’re already a well-established and trusted brand and are in this for the long haul.<br/>Once we accomplish one milestone, we are eager to move onto the next goal.</p>
                  <div className="row">
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadmap1">
                           <h1>2016 - Present</h1>
                           <p>Massage Club was founded<br/>Hiring efforts at scale made us a national brand<br/>Thousands of massage experts connected with us<br/>Clients can schedule professional and reliable home service<br/>Serving more than 200+ cities in Canada and the U.S.A.<br/>A team of experienced website and app developers</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - January</h1>
                           <p>Idea for the NFT project came to light<br/>Discussions with Blockchain experts about the project<br/>Research and developing resources to streamline the process<br/><span>Completed</span></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - February</h1>
                           <p>The Zen Lion NFT name was born<br/>Company utility for project value added<br/>Meeting with visual artists to materialize the NFT vision<br/>Worked with development team to enhance current website<br/>Ready for token redemption<br/><span>Completed</span></p>
                        </div>
                     </div>
                  </div>
                  <div className="row roadmap_row1">
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadmap1 payout">
                           <h1>2022 - March</h1>
                           <p>NFT design complete<br/>Consult and hiring of a solidarity developer with Web3 knowledge<br/>Smart contract started with minting functionality Creating communications channels; Discord, Instagram, Twitter and TikTok<br/>Getting the word out and starting the project hype<br/>Smart contract complete and reviewed<br/>Web page complete with minting functionality<br/>Hiring NFT marketing team and social media influencers<br/><span>Completed</span></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma10">
                           <h1>2022 - April</h1>
                           <p>Social media experts hired to manage our new Zen Lion NFT Instagram, Twitter and TikTok accounts!<br/>Discord experts hired to grow our community of Zen Lions.<br/>FAQ added for project support. <br/>Help us get the word out!<br/>Join our Discord or post to your socials and tag us <b>@ZenLionNFT</b> <br/><span>Social media content will be ongoing…</span></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma10">
                           <h1>2022 - May</h1>
                           <p>Building the hype for our token release date<br/>Growing our community of Zen Lions!</p>
                        </div>
                     </div>
                  </div>
                  {/* <div className="row roadmap_row1">
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - TBA Whitelist Pre-sale Mint date</h1>
                           <p>Whitelist gets early access<br/>Buy the Zen Lion NFT before public sale<br/>Cost per token will be 0.3 ETH<br/>Details in our <b>Purchasing FAQ</b></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - TBA Public sale Mint date</h1>
                           <p>Public sale to buy the Zen Lion NFT<br/>Cost per token will be 0.6 ETH<br/>Connect your wallet and have some ETH ready so you don’t miss out!</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - TBA</h1>
                           <p>Zen Lion NFT holders can start using its utility!<br/>How to redeem is in our <b>Token redemption FAQ</b> <br/>Book <b>WEEKLY</b> 60-minute in-home massages of your choice<br/>Valid in any of our 200+ major markets plus the surrounding cities<br/>We currently serve Canada and the United States!<br/><b>*For personal use</b> (or in the name of the person you gift the token utility to)<br/><b>*Max 1 session per week </b>($120 value per session, $6240 per year)<br/><b>*Valid</b> in 2022 & 2023<br/><b>*Transferrable</b> as the NFT exchanges ownership</p>
                        </div>
                     </div>
                  </div> */}
                  <div className="row roadmap_row1">
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - June</h1>
                           <p>A national hiring effort will begin<br/>Employing more top talent in current and new cities<br/>Focusing on Canada and the United States<br/>Extending service area out another 100-200km<br/><span>Hiring will be ongoing…</span></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - July</h1>
                           <p>Investment into our iOS and Android App<br/>Enhancing features and the user experience<br/><span>App and website development will be ongoing…</span></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2022 - August</h1>
                           <p>A global hiring effort will begin<br/>Employing top talent in select new countries<br/>Email info@massageclub.ca to let us know if we should be in your city!<br/>Once we establish a footing in a new market we will start serving clients<br/><span>Expansion efforts will be ongoing…</span></p>
                        </div>
                     </div>
                  </div>
                  <div className="row roadmap_row1">
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2023 - MARCH 13 AT 10:00 AM PUBLIC MINT</h1>
                           <p>Public sale to buy the Zen Lion NFT<br/>Cost per token is <b>0.03 ETH</b><br/>Connect your wallet and have<b> ETH + gas</b> <br/>Mint a Zen Lion NFT from any wallet, no limit<br/></p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2023 - MARCH 20 (UTILITY REDEMPTION)</h1>
                           <p>Zen Lion NFT holder’s can redeem utility! <br/>How to is on<b> Token Redemption FAQ</b> <br/>Book single, couple or group sessions <br/>Get<b> $20 OFF</b> any in-home massage service <br/>Valid in <b>200+ markets</b> plus surrounding cities <br/>Serving Canada and the United States <br/><br/>*For <b>personal use</b> (or gift at time of redemption) <br /><b>*Max</b>  apply one code per booking <br /><b>*Valid</b> for bookings only, no expiry <br /><b>*Not valid </b>for Gift Card or Product orders<br /><b>*Transferrable</b> as the NFT exchanges ownership</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="content_roadmap content_roadma2">
                           <h1>2023 - THE JOURNEY CONTINUES</h1>
                           <p>Keep active in our Discord community! <br/>Help us grow across our socials! <br/>Stay tuned for fun giveaways! <br/>Holders will get more perks in the future!<br/></p>
                        </div>
                     </div>
                  </div>
                  {/* <div className="row roadmap_row1">
                     <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content_roadmap content_roadma2 last-roadmap">
                           <h1>2022 - September</h1>
                           <p>Team meetings for the next NFT feature:<br/>Couples massage and/or unlimited redemption options <br/></p>
                        </div>
                     </div>
                  </div> */}
               </div>
            </div>
          </>
            
     );
}
 
export default Roadmap;