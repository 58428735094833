const Team = () => {
    return ( 
        <>
         <div className="team" id="team">
            <div className="container">
               <div className="team_overlay">
                  <div className="grid grid-cols-1 md:grid-cols-6 justify-center">
                        <div className="mx-4 text-center text-white">
                           <img className="h-auto w-full" src="images/Founder1.png" alt=""/>
                           <h2 className="gradient-title">Meet The team</h2>
                           <p>Massage Club was founded in 2016 by current CEO, Adrian Sinacori, who’s vision was to create a platform that would benefit peoples lives at scale; offering affordable self-care at home, while employing a vast number of professionals who could control their own hours and self-schedule. Working alongside a dedicated team of web experts and app engineers this allowed the simplest to the most complex ideas to come to life. Advanced software was developed with the client in mind, allowing convenient online booking on a national level with ease.</p>
                           <h4 className="text-sm"></h4>
                           <a href="https://www.linkedin.com/in/adrian-sinacori-0683a4196/" target="_blank"><i className="fa fa-linkedin"></i></a>
                        </div>
                        <div className="mx-4 text-center text-white"></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="team" id="team1">
            <div className="container">
               {/* <h4>MARKETING dIRECTOR</h4> */}
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/MarketingDirector1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">MASSAGE CLUB</h1><hr/><h3>Marketing Director</h3><a href="https://www.linkedin.com/company/massage-club/about/?viewAsMember=true" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4>
               
               </div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/massageTherapists1.png" id="hiring"  alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">3000+ Active Members</h1><hr/><h3>Massage Therapists</h3><a href="https://www.linkedin.com/company/massage-club/videos/?viewAsMember=true/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/ManagementTeam1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">MASSAGE CLUB</h1><hr/><h3>Management Team
</h3><a href="https://www.linkedin.com/in/adrian-sinacori-0683a4196/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div></div>

               {/* <h4 id="hiring">Hiring Director</h4> */}
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/WebDevelopmentTeam1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">SilktoWeb</h1><hr/><h3>Web Development Team</h3><a href="https://silktoweb.com/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/iOSEngineers1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">SilktoWeb</h1><hr/><h3>iOS Engineers</h3><a href="https://silktoweb.com/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/AndroidEngineers1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">SilktoWeb</h1><hr/><h3>Android Engineers</h3><a href="https://silktoweb.com/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div></div>

               {/* <h4>Project Managers</h4> */}
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/DigitalArtists1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">SilktoWeb</h1><hr/><h3>Digital Artists</h3><a href="https://silktoweb.com/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/Web3Developers1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">SilktoWeb</h1><hr/><h3>Web3 Developers</h3><a href="https://silktoweb.com/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/DiscordManager1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">MassageClub</h1><hr/><h3>Discord Managers</h3><a href="https://www.linkedin.com/in/adrian-sinacori-0683a4196/" target="_blank" class="icont"><i class="fa fa-linkedin icon"></i></a><h4 class="text-sm"></h4></div></div>

               {/* <h4>Web Development Team</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>Web Developer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>Web Developer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>Web Developer</h3><h4 className="text-sm"></h4></div></div>

               <h4>iOS Engineers</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>iOS Engineer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>iOS Engineer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>iOS Engineer</h3><h4 className="text-sm"></h4></div></div>

               <h4>Android Engineers</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>Android Engineers</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>Android Engineers</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>Android Engineers</h3><h4 className="text-sm"></h4></div></div>

               <h4>Digital Artist</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>Digital Artist</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>Digital Artist</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>Digital Artist</h3><h4 className="text-sm"></h4></div></div>

               <h4>Web3 Developer</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>Web3 Developer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>Web3 Developer</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>Web3 Developer</h3><h4 className="text-sm"></h4></div></div>

               <h4>Discord Manager</h4>
               <div className="grid grid-cols-2 md:grid-cols-6 justify-center"><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Peter</h1><hr/><h3>Discord Manager</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Frankie</h1><hr/><h3>Discord Manager</h3><h4 className="text-sm"></h4></div><div className="mx-4 text-center text-white"><img className="h-auto w-full" src="images/img1.png" alt=""/><h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Dorian</h1><hr/><h3>Discord Manager</h3><h4 className="text-sm"></h4></div></div> */}


               <div id="team team-para">
                  <p>*The founder and management team would be happy to come out of the den and connect with you anytime<br/>Our valued and dedicated web/app/web3/design team is our tech foundation and they're all busy working away keeping the machine well oiled, but will come out to play with the other lions once our future goals are met and they have some spare time.</p>
               </div>
            </div>
         </div>

         {/* <!-- Mission Start--> */}
         <div id="welcome">
            <div class="max-w-5xl mx-auto">
               <div class="container">
                  <div class="text-white">
                     <h2 class="gradient-title">Mission</h2>
                     <p>Massage Club’s mission is simple- help local communities feel better by relieving their aches and pains while offering an avenue for employment to professionals in this space who are pursuing massage therapy as a career.<br/><br/>The introduction of the Zen Lion NFT is an excellent tool to assist us on our expansion journey to help more people enjoy our home services and offer further employment opportunity at scale for those who would like to join our team.</p><br/><br/><h2 class="gradient-title">Goal</h2><p>As a well-established business and leader in the industry, we are continuously learning, developing new software for ease of scheduling and connecting with thought leaders and like-minded individuals to share ideas for growth and how to give back to the community.<br/><br/>While we may not be able to rebuild city infrastructure, we can still be hands on (quite literally) to assist with optimizing the body for enjoyment of life in the communities we serve.<br/><br/>Whether someone has a mobility issue, or simply wishes to take a break from life’ and enjoy a moment of zen during an hour of pampering while in the comfort of their home, we are at your service. <br/><br/>With all that is happening in the world, the ‘feel-good’ for our team is to give back as much as we can.<br/><br/>Proceeds from this project will be used to pay in part for our service utility and as tokens transfer ownership, as well as reinvest in the company’s growth with further software advancement and technology to enhance the user experience and assist us in our expansion efforts across North America and then globally. </p>
                  </div>
               </div>
            </div>
         </div>
        </>
        
     );
}
 
export default Team;