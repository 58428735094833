const Redemption = () => {
    return (
        <>
            <div id="redemption">
                <div id="section7">
                    <div className="max-w-5xl mx-auto container">
                        <div className="grid ">
                            <div className="text-white">
                                <h2 className="gradient-title">HOW TO REDEEM</h2>
                                <p>On or after redemption day (March 20, 2023) email info@zenlion.ca with your <b>NFT #</b>  plus screen shot of the<b> top portion of your MetaMask wallet</b> you minted from to verify your ownership. </p><br /><p>*The <b>$20 OFF</b> code is available for original owners who mint on https://ZenLion.ca and is also transferrable to any new owner who may purchase on a secondary market such as OpenSea. Our utility has no expiration, the only limitation is the ability to apply one code per booking. </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Section8 Start --> */}
                <div id="section8">
                    <div className="sec8_overlay">
                        <div className="max-w-5xl mx-auto">
                            <div className="container">
                                <div className="text-white">
                                    <h2 className="gradient-title">Our project is different</h2>
                                    <p>You not only get a unique piece of 3D art that is a cool collectible to enjoy and trade, but you receive a valuable home service utility that you can <b>redeem now</b>, and while it’s only<b> $20 OFF</b> per booking, it has unlimited uses (apply 1 per booking) for life!  <br /><br />Support our established brand as we embark on an expansion effort to scale nationally, helping us to employ thousands more to bring massage therapy to more communities!<br />
                                        <br />If you live in a city we serve, great! <br /><br />Hold the NFT and enjoy our services, then sell it to recoup your investment or profit.<br /><br />If you’d like to buy our NFT in a country other than Canada or the United States, these tokens still hold great value- you can invest in us as we expand operations or hold and flip on OpenSea to clients who will benefit from service in our North American markets. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Redemption;