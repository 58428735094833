import React, {useState, useEffect} from "react";

export default function CountdownTimer(){
    const [timerDays,seTimerDays] = useState();
	const [timerHours,seTimerHours] = useState();
	const [timerMinutes,seTimerMinutes] = useState();
	const [timerSeconds,seTimerSeconds] = useState();
	let interval;
	const startTimer=()=>{
		const countDownDate= new Date("March 13,2023").getTime();
		interval = setInterval(()=>{
			const now1 = new Date().getTime();
			const distance = countDownDate - now1;
			const days = Math.floor(distance / (24 * 60 * 60 * 1000));
			const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / ( 1000 * 60 * 60 ));
			const minutes = Math.floor((distance % ( 60 * 60 * 1000)) / ( 1000 * 60 ));
			const seconds = Math.floor((distance % ( 60 * 1000)) / ( 1000 ));
			
			if(distance<0){
				clearInterval(interval.current);
			}else{
				seTimerDays(days);
				seTimerHours(hours);
				seTimerMinutes(minutes);
				seTimerSeconds(seconds);
			}
		});
	}
	useEffect(()=>{
		startTimer();
	})
    return(
            <div class="countdown">{timerDays || '0'} : {timerHours || '00'} : {timerMinutes || '00'} : {timerSeconds || '00'}</div>
    )
}