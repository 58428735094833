const Whitelist = () => {

    return (
        <>
            <div className="home_community" id="whitelist">
                <div className="container">
                    <div className="home_road_map_heading">
                        <h3>VIP LIST</h3>
                    </div>
                    <div className="home_community_flex">
                        <div className="home_community_inner aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                            <div className="home_community_text">
                                <div className="inner_text1">
                                    {/* <p>In total there will be <b>150 whitelists spots</b> for the pre-sale on <b>TBA, 2022.</b><br /><br />Each whitelist spot will be able to mint a <b>maximum of 2</b> Zen Lion NFTs.<br />Participate in one of the following for your chance to get whitelisted for the pre-sale:<br /><br />

                                        <span className="number">1</span><br />
                                        10 whitelists will be granted to the top <b>10 on the leaderboard</b> for guests on Discord, for this generate a custom invitation link from discord and share it.<br /><br />

                                        <span className="number">2</span><br />
                                        10 whitelists will be awarded to anyone who has <b>invited at least 5 people on Discord</b> via a random draw shortly before the presale, to do this generate a personalized invitation link from discord and share it.<br /><br />

                                        <span className="number">3</span><br />
                                        10 whitelists will be granted to members who have <b>contributed in a “natural” and beneficial way</b> to the project on Discord.<br /><br />

                                        <span className="number">4</span><br />
                                        10 whitelists will be awarded via <b>YouTube video contribution</b>, send us an email to info@massageclub.ca noting you are in the process of creating content for YouTube on your channel, video length or channel type does not matter, and the first 10 people to do so will get an automatic spot on our whitelist!<br /><br />

                                        <span className="number">5</span><br />
                                        10 whitelists will be winnable via giveaway on Twitter,make a post and tag @ZenLionNFT to enter.<br /><br />

                                        <span className="number">6</span><br />
                                        10 whitelists will be winnable via giveaway on Instagram, make a post and tag @ZenLionNFT to enter.<br /><br />

                                        <span className="number">7</span><br />
                                        10 whitelists will be winnable via giveaway on Tiktok, make a post and tag @ZenLionNFT to enter.<br /><br />

                                        <span className="number">8</span><br />
                                        30 whitelists will be reserved for <b>active massage members</b> on our team, draw to be announced privately by company email on TBA.<br /><br />

                                        <span className="number">9</span><br />
                                        30 whitelists will be reserved for <b>registered clients</b> of Massage Club, first who show interest after a marketing email to be sent in the coming week. Names will be announced privately by company email on TBA.<br /><br />

                                        <span className="number number10">10</span><br />
                                        20 whitelists will be reserved for <b>social media influencers</b> who want to help us promote the project, email info@massageclub.ca to discuss your idea, the first who contact us and their idea accepted will get whitelisted.</p> */}
                                        <p>The Zen Lion NFT<b> Public Mint</b>  is on<b> March 13, 2023</b>. <br/><br/>There is no Whitelist or Pre-sale, instead we are inviting you on our<b> VIP List!</b> <br/><br/>To join,<b> open a ticket on our Zen Lion NFT Discord</b> and ask for the <b>“VIP Lion”</b> role! <br/><br/>You’ll gain access to a private channel which hosts exclusive events, offers free NFT giveaways and other perks, along with pertinent mint date reminders! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="home_community" id="home-community1">
                <div className="container">
                    <div className="home_community_flex">
                        <div className="home_community_inner aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                            <div className="home_community_text">
                                <div className="inner_text1">
                                    <p>All whitelist winners will be publicly announced 3 days leading up to the presale date, so on <b>TBA, 2022 </b> and via the platform you entered on whether; Discord, Twitter, Instagram, Tiktok. All winners will also be privately messaged noting their whitelist access and link to the pre-sale mint page for NFT purchase. If you’re a YouTube creator, active team member or client of Massage Club it will be a private message only via email.<br /><br />If the private pre-sale is not fulfilled, there will be a public pre-sale round with a maximum allocation of 2 Zen Lion NFTs per non-whitelist wallet.
                                        <br /><br />The whitelist pre-sale date is <b>TBA, 2022 </b>but we reserve the right to change the date if necessary.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


        </>
    );
}

export default Whitelist;