const Footer = () => {
    return ( 
        <>
         {/* <!-- Invite Section --> */}
            <div className="relative bg-black pt-12 section15">
               <div className="section15Overlay">
                  <div className="h-64">
                     <div className="h-full flex items-center">
                           <div className="w-full sm:w-2/3 text-white text-center">
                              <h1 className="text-6xl font-restaurant uppercase"> We invite you to join the pride with <br/>our Zen Lion NFT</h1>
                              <p>Enjoy a spa-quality massage in the comfort of your home, office, or hotel and book on-demand.<br/><br/>Perfect for personal pampering, routine therapy or body maintenance so you can be the optimal you.<br/><br/>Your body is your vessel and it will appreciate you.</p>
                           </div>
                     </div>
                  </div>
               </div>
         </div> 
         
         {/* <!-- COntract Address --> */}
         <div className="contract-address">
            <h1>Verified Smart Contract Address:     
               <span>
               <a href= "https://etherscan.io/address/0xB3d0e99C206A82EBC4df245B5943061024666c85"target="_blank">0xb3d0e99c206a82ebc4df245b5943061024666c85</a>
               </span>
            </h1>
         </div>

         {/* <!-- Footer --> */}
         <nav className="footer p-10 lg:p-16">
            <div className="container">
               <div className="row">
                  <div className="col col-lg-4 col-md-4 col-sm-12">
                     <a href="#faq" className="terma-conditions">Terms and Conditions </a>
                     <div className="footer-setion">
                     <a href="#whitelist" className="button _2nd w-button">Get on the VIP List </a>
                     </div>
                  </div>
                  <div className="col col-lg-4 col-md-4 col-sm-12">
                     <div className="flex flex-col mx-auto text-center space-y-6">
                     <h1  className="follow-zenlion"> Follow the Zen Lions!</h1>
                     <img src="images/Lol.png" alt="bing bong" className="inline-block w-16 text-white md:w-24"/>
                     </div>
                  </div>
                  <div className="col col-lg-4 col-lg-4 col-sm-4 right-side">
                     <h1>Join the pride</h1>
                     <div className="space-x-4">
                     <a className="" href="https://twitter.com/ZenLionNFT" target="_blank">
                        <img src="images/twitter.svg" alt="Twitter" className="inline-block w-6 text-white md:w-8"/>
                     </a>
                     <a className="" href="https://www.instagram.com/ZenLionNFT/" target="_blank">
                        <img src="images/instagram.svg" alt="Instagram" className="inline-block w-6 text-white md:w-8"/>
                     </a>
                     <a className="" href="https://discord.com/invite/W3Q2PVN3qY" target="_blank">
                        <img src="images/discord.svg" alt="Discord" className="inline-block w-6 text-white md:w-8"/>
                     </a>
                     <a href="https://www.tiktok.com/@zenlionnft" className="tiktok-img" target="_blank">
                        <img src="images/tiktok_PNG271.png"/>
                     </a>
                     </div>
                  </div>
               </div>
            </div>
         </nav>

         {/* <!-- Bottom Footer --> */}
         <footer className="bg-black font-brandon">
            <div className="px-4 overflow-hidden sm:px-6 lg:px-8">
               <div className="pt-3 pb-3">
                  <div className="slogan">
                     <p>Live life, be happy and keep well.</p>
                  </div>
                  <div className="w-1/3 text-gray-700  text-xs sm:text-base">
                     © 2023 Zen Lion Massage Club - All Rights Reserved.
                  </div>
               </div>
            </div>
         </footer>
        </>
     );
}
 
export default Footer;