import React, { useState, useEffect } from 'react';
import CountdownTimer from './CountdownTimer';

const Buy = () => {
    const [countdownDate, setCountdownDate] = useState(new Date('03/13/2023').getTime());
	const [state, setState] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
    useEffect(() => {
		setInterval(() => setNewTime(), 1000);
	}, []);
	const setNewTime = () => {
		if (countdownDate) {
		const currentTime = new Date().getTime();

		const distanceToDate = countdownDate - currentTime;

		let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
		let hours = Math.floor(
		(distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
		);
		let minutes = Math.floor(
		(distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
		);
		let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

		const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

		days = `${days}`;
		if (numbersToAddZeroTo.includes(hours)) {
		hours = `0${hours}`;
		} else if (numbersToAddZeroTo.includes(minutes)) {
		minutes = `0${minutes}`;
		} else if (numbersToAddZeroTo.includes(seconds)) {
		seconds = `0${seconds}`;
		}

		setState({ days: days, hours: hours, minutes, seconds });
	}
	};
	
    return ( 
        <>
          <div className="relative bg-black pt-12 join-discord sdsdsdsdsdsdsdsds" id="buy-a-lion">
            <div className="h-64">
                <div className="h-full flex items-center">
                <div className="w-full sm:w-2/3 text-white text-center">
                    <h1 className="text-6xl font-restaurant uppercase"> Earn a FREE Zen Lion NFT</h1>
                    <p>A mission of ours is to always benefit the community.<br/><br/>If you have an interesting giveaway to your followers or a fundraiser type promotion for charity or a humanitarian effort we would love to hear your idea!<br/><br/>We have 20 tokens reserved for such contributions.<br/><br/>Contact our team by email info@zenlion.ca to share your idea and for more details.</p>
                </div>
                </div>
            </div>
          </div> 
         

         	{/* <!-- section5 Start--> */}
            <div id="section5">
                <div class="sec5_overlay">
                    <div class="max-w-5xl mx-auto">
                        <div class="container">
                            <div class="text-white">
                                <h2 class="gradient-title"></h2>
                                <p>Review the <b>massage styles</b> we offer and check our <b>service areas</b> to see if we visit your neck of the woods!</p><br/><br/>
                                <a href="https://massageclub.ca/" target="_blank" class="button _2nd w-button">Visit MassageClub.ca</a><br/><br/><br/>Schedule conveniently on our <b>website</b> and book a single, couple or group session!<br/><br/>*Appointments subject to availability if booking on-demand or next day
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- CountDoswn Timer --> */}
            <div className="countdown1">
                <div class="container">
                    {/* <h1>Pre-sale</h1> */}
                    {/* <p>TBA (01:00 PM EST)</p> */}
                    {/* <div class="countdown">00 : 00 : 00 : 00</div> */}
                    <div class="publicSale">
                        <h1>PUBLIC MINT </h1>
                        <p> March 13 at 10:00 AM EST </p>
                        {/* <div class="countdown2">{state.days || '0'} : {state.hours || '00'} : {state.minutes || '00'} : {state.seconds || '00'}</div> */}
                        <CountdownTimer />
                    </div>
                </div>
            </div>
		</>
     );
}
 
export default Buy;